import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class LessonPlanning extends Model {
  static entity = "lessons-planning";

  static fields() {
    return {
      data: this.attr({}),
      meta: this.attr({}),
    };
  }

  static async getLessonsPlanning(pag = 1, filters) {
    await this.api()
      .get(`/lesson-planning?page=${pag || 1}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          filters,
        },
      })
      .then(async () => {
        let result = this.query().all();
        if (result.length > 1) {
          let first = this.query().first();
          await this.delete(first.$id);
        }
      });
  }

  static async saveLessonPlanning(data) {
    const formData = this.setFormData(data);

    await http.post("lesson-planning", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  static async updateLessonPlanning(data, id) {
    const formData = this.setFormData(data);
    formData.append("_method", "PUT");

    await http.post(`lesson-planning/${id}/update`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  static setFormData(data) {
    const formData = new FormData();
    formData.append("data[planning_type]", data.planning_type);
    formData.append(
      "data[class_planning][period][date_start]",
      data.class_planning.period.date_start
    );
    formData.append(
      "data[class_planning][period][date_end]",
      data.class_planning.period.date_end
    );
    formData.append("data[class_planning][group]", data.class_planning.group);

    if (data.class_planning.module) {
      formData.append(
        "data[class_planning][module]",
        data.class_planning.module
      );
    }

    data.learning_objective.forEach((learning_objective, index) => {
      formData.append(
        `data[learning_objective][${index}][component]`,
        learning_objective.component
      );

      learning_objective.specifications.forEach(
        (specification, specifiationIndex) => {
          formData.append(
            `data[learning_objective][${index}][specifications][${specifiationIndex}][id]`,
            specification.id
          );
          formData.append(
            `data[learning_objective][${index}][specifications][${specifiationIndex}][text]`,
            specification.text
          );
          formData.append(
            `data[learning_objective][${index}][specifications][${specifiationIndex}][bncc_id]`,
            specification.bncc_id
          );
        }
      );

      learning_objective.abilities.forEach((ability, abilityIndex) => {
        formData.append(
          `data[learning_objective][${index}][abilities][${abilityIndex}][id]`,
          ability.id
        );
        formData.append(
          `data[learning_objective][${index}][abilities][${abilityIndex}][text]`,
          ability.text
        );
      });
    });

    data.knowledge_objective.contents.forEach((content, index) => {
      formData.append(
        `data[knowledge_objective][contents][${index}][label]`,
        content.label
      );
      formData.append(
        `data[knowledge_objective][contents][${index}][value]`,
        content.value
      );
    });

    formData.append(
      "data[knowledge_objective][methodology]",
      data.knowledge_objective.methodology
    );
    formData.append(
      "data[knowledge_objective][activities]",
      data.knowledge_objective.activities || ""
    );
    formData.append(
      "data[knowledge_objective][didactic_resources]",
      data.knowledge_objective.didactic_resources || ""
    );
    formData.append(
      "data[knowledge_objective][adaptation_record]",
      data.knowledge_objective.adaptation_record || ""
    );
    formData.append(
      "data[knowledge_objective][references]",
      data.knowledge_objective.references || ""
    );

    if (data.file) {
      formData.append("data[file]", data.file);
    }

    return formData;
  }

  static async getLessonPlanningById(id) {
    const { data } = await http.get(`lesson-planning/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return data.data;
  }

  static async deleteLessonPlanning(id) {
    const result = await http.delete(`lesson-planning/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  static async getValidateLessonsPlanning(pag = 1, filters) {
    await this.api().get(`/validate-lesson-planning?page=${pag || 1}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        filters,
      },
    });
  }

  static async updateValidationLessonPlanning(data, id) {
    await http.put(
      `validate-lesson-planning/${id}/update`,
      { data },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  }
}
