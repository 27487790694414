export default [
  {
    path: "/app/settings",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Configurações",
      requiresAuth: true,
    },
    children: [
      /* User Type */
      {
        path: "user-type",
        name: "settings.user-type",
        component: () => import("../user-type/views/List.vue"),
      },
      {
        path: "user-type/create",
        name: "settings.user-type.create",
        component: () => import("../user-type/views/Create.vue"),
      },
      {
        path: "user-type/create/:id",
        name: "settings.user-type.edit",
        component: () => import("../user-type/views/Edit.vue"),
      },
      /* Params */
      {
        path: "params",
        name: "settings.params",
        component: () => import("../params/views/List.vue"),
      },
    ],
  },
];
