export default [
  {
    path: "/app/school",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Escola",
      requiresAuth: true,
    },
    children: [
      /* Register */
      /* Students */
      {
        path: "register/student",
        name: "school.register.student",
        component: () => import("../register/students/views/List"),
      },
      {
        path: "register/student/create",
        name: "school.register.student.create",
        component: () => import("../register/students/views/Create"),
      },
      /* Institution */
      {
        path: "register/institution",
        name: "school.register.institution",
        component: () => import("../register/institution/views/List"),
      },
      {
        path: "register/institution/create",
        name: "school.register.institution.create",
        component: () => import("../register/institution/views/Create"),
      },
      {
        path: "register/institution/edit/:id",
        name: "school.register.institution.edit",
        component: () => import("../register/institution/views/Edit"),
      },
      /* PROCESS */
      {
        path: "process",
        name: "school.process.historic-school",
        component: () => import("../process/historic_school/views/List"),
      },
      {
        path: "process/show/student/:id",
        name: "school.process.historic-school.show",
        component: () => import("../process/historic_school/views/Show"),
      },
      /* Benefits */
      {
        path: "register/types/benefits",
        name: "school.register.type.benefits",
        component: () => import("../register/type/benefits/views/List"),
      },
      {
        path: "register/types/benefits/create",
        name: "school.register.type.benefits.create",
        component: () => import("../register/type/benefits/views/Create"),
      },
      {
        path: "register/types/benefits/edit/:id",
        name: "school.register.type.benefits.edit",
        component: () => import("../register/type/benefits/views/Edit"),
      },
      /* Modules */
      {
        path: "register/types/modules",
        name: "school.register.types.modules",
        component: () => import("../register/type/modules/views/List.vue"),
      },
      {
        path: "register/types/modules/create",
        name: "school.register.types.modules.create",
        component: () => import("../register/type/modules/views/Create.vue"),
      },
      {
        path: "register/types/modules/edit/:id",
        name: "school.register.types.modules.edit",
        component: () => import("../register/type/modules/views/Edit.vue"),
      },
      /* Types */
      /* Curricular Components */
      {
        path: "curricular-components",
        name: "school.curricular-components",
        component: () => import("../curricular-components/views/List"),
      },
      {
        path: "curricular-components/create",
        name: "school.curricular-components.create",
        component: () => import("../curricular-components/views/Create"),
      },
      {
        path: "curricular-components/edit/:id",
        name: "school.curricular-components.edit",
        component: () => import("../curricular-components/views/Edit"),
      },

      /* Courses */
      {
        path: "courses",
        name: "school.courses",
        component: () => import("../courses/views/List"),
      },
      {
        path: "courses/create",
        name: "school.courses.create",
        component: () => import("../courses/views/Create"),
      },
      {
        path: "courses/edit/:id",
        name: "school.courses.edit",
        component: () => import("../courses/views/Edit"),
      },
      {
        path: "courses/show/:id",
        name: "school.courses.show",
        component: () => import("../courses/views/Show"),
      },

      /*  grade */
      {
        path: "grade",
        name: "school.grade",
        component: () => import("../grade/views/List"),
      },
      {
        path: "grade/create",
        name: "school.grade.create",
        component: () => import("../grade/views/Create"),
      },
      {
        path: "grade/edit/:id",
        name: "school.grade.edit",
        component: () => import("../grade/views/Edit"),
      },
      {
        path: "grade/show/:id",
        name: "school.grade.show",
        component: () => import("../grade/views/Show"),
      },

      /*  school-grade */
      {
        path: "school-grade",
        name: "school.school-grade",
        component: () => import("../school-grade/views/List"),
      },
      {
        path: "school-grade/create",
        name: "school.school-grade.create",
        component: () => import("../school-grade/views/Create"),
      },
      {
        path: "school-grade/edit/:id/:school_id",
        name: "school.school-grade.edit",
        component: () => import("../school-grade/views/Edit"),
      },
      {
        path: "school-grade/show/:id/:school_id",
        name: "school.school-grade.show",
        component: () => import("../school-grade/views/Show"),
      },

      /*  school */
      {
        path: "school",
        name: "school.school",
        component: () => import("../register/school/views/List"),
      },
      {
        path: "school/create",
        name: "school.school.create",
        component: () => import("../register/school/views/Edit"),
      },
      {
        path: "school/edit/:id",
        name: "school.school.edit",
        component: () => import("../register/school/views/Edit"),
      },
      {
        path: "school/show/:id",
        name: "school.school.show",
        component: () => import("../register/school/views/Show"),
      },
      /* Bncc */
      {
        path: "bncc",
        name: "school.bncc",
        component: () => import("../bncc/views/List.vue"),
      },
      {
        path: "bncc/edit/:id",
        name: "school.bncc.edit",
        component: () => import("../bncc/views/Edit.vue"),
      },
      {
        path: "bncc/add-many",
        name: "school.bncc.add-many",
        component: () => import("../bncc/views/AddMany.vue"),
      },

      /*Process*/
      {
        path: "/process/bot-service",
        name: "school.process.bot-service",
        component: () => import("../process/bot-service/views/List.vue"),
      },

      /*Groups*/
      {
        path: "groups",
        name: "school.register.group",
        component: () => import("../register/group/views/List.vue"),
      },
      {
        path: "groups/create",
        name: "school.register.group.create",
        component: () => import("../register/group/views/Create.vue"),
      },
      {
        path: "groups/edit/:id",
        name: "school.register.group.edit",
        component: () => import("../register/group/views/Edit.vue"),
      },
      /*School Year */
      {
        path: "school-year/:id",
        name: "school-year-stage",
        component: () => import("../school-year/views/Create.vue"),
      },
      {
        path: "school-year/show/:id",
        name: "school-year-stage.show",
        component: () => import("../school-year/views/Show.vue"),
      },
      {
        path: "school-year/edit/:id",
        name: "school-year-stage.edit",
        component: () => import("../school-year/views/Edit.vue"),
      },
      /* Blocking Notes Absences */
      {
        path: "blocking-notes-absences",
        name: "school.params.blocking-notes-absences",
        component: () =>
          import("../params/blocking-notes-absences/views/List.vue"),
      },
      {
        path: "blocking-notes-absences/create",
        name: "school.params.blocking-notes-absences.create",
        component: () =>
          import("../params/blocking-notes-absences/views/Create.vue"),
      },
      {
        path: "blocking-notes-absences/edit/:id",
        name: "school.params.blocking-notes-absences.edit",
        component: () =>
          import("../params/blocking-notes-absences/views/Edit.vue"),
      },
      {
        path: "blocking-notes-absences/show/:id",
        name: "school.params.blocking-notes-absences.show",
        component: () =>
          import("../params/blocking-notes-absences/views/Show.vue"),
      },

      /* School Year Block*/
      {
        path: "school-year-block",
        name: "school.params.school-year-block",
        component: () => import("../params/school-year-block/views/List.vue"),
      },
      {
        path: "school-year-block/create",
        name: "school.params.school-year-block.create",
        component: () => import("../params/school-year-block/views/Create.vue"),
      },
      {
        path: "school-year-block/edit/:id",
        name: "school.params.school-year-block.edit",
        component: () => import("../params/school-year-block/views/Edit.vue"),
      },
      {
        path: "school-year-block/show/:id",
        name: "school.params.school-year-block.show",
        component: () => import("../params/school-year-block/views/Show.vue"),
      },
    ],
  },
];
