export default [
  {
    path: "/app/coordination",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Coordenação",
      requiresAuth: true
    },
    children: [
      /* Validate Frequency */
      {
        path: "validate-frequency",
        name: "coordination.validate-frequency",
        component: () => import("../validation/validate-frequency/views/List")
      },
      {
        path: "validate-frequency/show/:id",
        name: "coordination.validate-frequency.show",
        component: () => import("../validation/validate-frequency/views/Show")
      },
      /* Validate LessonPlanning */
      {
        path: "validate-lesson-planning",
        name: "coordination.validate-lesson-planning",
        component: () =>
          import("../validation/validate-lesson-planning/views/List")
      },
      {
        path: "validate-lesson-planning/show/:id",
        name: "coordination.validate-lesson-planning.show",
        component: () =>
          import("../validation/validate-lesson-planning/views/Show")
      },
      /* Validate LessonPlanning AEE */
      {
        path: "validate-lesson-planning-aee",
        name: "coordination.validate-lesson-planning-aee",
        component: () =>
          import("../validation/validate-lesson-planning-aee/views/List")
      },
      {
        path: "validate-lesson-planning-aee/show/:id",
        name: "coordination.validate-lesson-planning-aee.show",
        component: () =>
          import("../validation/validate-lesson-planning-aee/views/Show")
      },
      /* Validate Service-AEE */
      {
        path: "validate-service-aee",
        name: "coordination.validate-service-aee",
        component: () => import("../validation/validate-service-aee/views/List")
      },
      {
        path: "validate-service-aee/show/:id",
        name: "coordination.validate-service-aee.show",
        component: () => import("../validation/validate-service-aee/views/Show")
      },
      /* Absent Delay */
      {
        path: "absent-delay",
        name: "coordination.absent-delay",
        component: () => import("../absent-delay/views/List.vue")
      },
      {
        path: "absent-delay/create",
        name: "coordination.absent-delay.create",
        component: () => import("../absent-delay/views/Create.vue")
      },
      {
        path: "absent-delay/edit/:id",
        name: "coordination.absent-delay.edit",
        component: () => import("../absent-delay/views/Edit.vue")
      },
      /* Absent Delay Replacement */
      {
        path: "class-replacement",
        name: "coordination.class-replacement",
        component: () => import("../class-replacement/views/List.vue")
      },
      /* Eventual Replacement */
      {
        path: "eventual-replacement",
        name: "coordination.eventual-replacement",
        component: () => import("../eventual-replacement/views/List.vue")
      },
      {
        path: "eventual-replacement/create",
        name: "coordination.eventual-replacement.create",
        component: () => import("../eventual-replacement/views/Create.vue")
      },
      {
        path: "eventual-replacement/show/:id",
        name: "coordination.eventual-replacement.show",
        component: () => import("../eventual-replacement/views/Show.vue")
      },
      {
        path: "eventual-replacement/edit/:id",
        name: "coordination.eventual-replacement.edit",
        component: () => import("../eventual-replacement/views/Edit.vue")
      },

      //----------------------------EVOLUTION REGISTER----------------------------
      {
        path: "evolution-register",
        name: "coordination.evolution-register",
        component: () => import("../evolution-register/views/List.vue")
      }
    ]
  }
];
